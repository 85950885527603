import React from "react";
// style
import "./Navbar.scss";
// components
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import { ReactComponent as Logo } from "../../assets/Logo-Tinker.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo-white.svg";
import LogoSMVisible from "../../assets/images/logo-white.png";
import { ReactComponent as DotsMenuIcon } from "../../assets/svg/dots.svg";
import { ReactComponent as ShopIcon } from "../../assets/svg/shop-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user-icon.svg";
// import { ReactComponent as Icon3D } from "../../assets/svg/3d.svg";
// import { ReactComponent as IconHolo } from "../../assets/svg/holo.svg";
// import { ReactComponent as IconCatalog } from "../../assets/svg/catalog.svg";
// import { ReactComponent as IconVr } from "../../assets/svg/vr.svg";
// import { FaUserGraduate, FaUserTie } from "react-icons/fa";
// import { GiTeacher } from "react-icons/gi";
// react router

const Navigation = () => {
    const scrollToTopPage = () => {
        window.scrollTo(0, 0);
    };

    const toggleNav = () => {
        let y = document.getElementsByClassName("navbar-toggler");
        if (typeof y[0] !== "undefined") y[0].click();
    };

    return (
        <Navbar
            id="navigation"
            collapseOnSelect
            expand="md"
            bg="white"
            fixed="top"
            className="font-nunito-bold"
        >
            <div className="cryptodata-navbar-container nav justify-content-between align-items-center">
                <Navbar.Brand>
                    <a href="/" className="logo-lg" onClick={() => scrollToTopPage()}>
                        <Logo className="nav-logo" />
                    </a>

                    <a
                        href="https://cryptodata.com/cart"
                        className="logo-sm"
                        onClick={() => scrollToTopPage()}
                    >
                        <ShopIcon className="shop-icon-sm" />
                    </a>
                </Navbar.Brand>
                <a href="/">
                    <img src={LogoSMVisible} alt="logo" className="logo-sm-visible" />
                </a>
                <Navbar.Toggle>
                    <DotsMenuIcon />
                </Navbar.Toggle>

                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-center"
                >
                    <Nav>
                        <Nav.Link
                            eventKey="2"
                            className="links font-gotham-light products-link"
                            href="https://cryptodata.com/products"
                        >
                            Products
                        </Nav.Link>

                        <Nav.Link
                            eventKey="2"
                            className="links font-gotham-light"
                            href="https://cryptodata.com/cyber-security"
                        >
                            Security
                        </Nav.Link>

                        <NavDropdown
                            title="Company"
                            id="collasible-nav-dropdown"
                            className="links font-gotham-light"
                        >
                            <div className="dropdown-container">
                                <NavDropdown.Item href="https://cryptodata.com/about-us">
                                    <p className="mb-0 font-gotham-light pl-0 pl-sm-3">
                                        About us
                                    </p>
                                </NavDropdown.Item>

                                <NavDropdown.Item href="https://news.cryptodata.com/">
                                    <p className="mb-0 font-gotham-light pl-0 pl-sm-3">News</p>
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    href="https://cryptodata.com/projects"
                                    className="projects-link"
                                >
                                    <p className="mb-0 font-gotham-light pl-0 pl-sm-3">
                                        Projects
                                    </p>
                                </NavDropdown.Item>
                            </div>
                        </NavDropdown>

                        <Nav.Link
                            eventKey="7"
                            className="links font-gotham-light"
                            href="https://cryptodata.com/shop"
                        >
                            Shop
                        </Nav.Link>
                    </Nav>

                    <div className="tools-container">
                        <UserIcon
                            className="user-icon"
                            onClick={() =>
                                window.open("https://cryptodata.com/login", "_self")
                            }
                        />

                        <ShopIcon
                            className="shop-icon"
                            onClick={() =>
                                window.open("https://cryptodata.com/cart", "_self")
                            }
                        />
                    </div>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default Navigation;
