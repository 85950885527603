import React, { useState } from "react";
// style
import "./Footer.scss";
// components
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/svg/logo-white.svg";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { InputGroup, FormControl } from "react-bootstrap";
// react router
import CryptoDataLogo from "./Assets/logo.png";
import logo from "../../assets/svg/logo-white.svg";
import { ReactComponent as FaceBookLogo } from "./Assets/cryptodata-facebook.svg";
import { ReactComponent as YouTubeLogo } from "./Assets/cryptodata-youtube.svg";
import { ReactComponent as TwitterLogo } from "./Assets/cryptodata-twitter.svg";
import { ReactComponent as TelegramLogo } from "./Assets/cryptodata-telegram.svg";
import { ReactComponent as LanguageIco } from "./Assets/language-icon.svg";
import { ReactComponent as Chevron } from "../../assets/svg/chevron-footer.svg";
import axios from "axios";

// IMPORTED IN APP
const Footer = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [succesfullySubscriber, setSuccesufullySubscriber] = useState(false);

  // INPUT FORM CONTROL
  const [emailValue, setEmailValue] = useState(() => "");
  const [emailErrorMessage, setEmailErrorMessage] = useState(() => "");

  const handleChange = (event) => {
    const { value } = event.target;
    setEmailValue(() => value);
    if (emailErrorMessage) {
      setEmailErrorMessage(() => "");
    }
  };

  const validateEmail = () => {
    let emailError;

    if (!emailValue) {
      emailError = "This field can't be empty";
      setEmailErrorMessage(emailError);
    } else if (!/\S+@\S+\.\S+/.test(emailValue)) {
      emailError = "Invalid email!";
      setEmailErrorMessage(emailError);
    } else {
      handleSubmit();
    }
  };

  const sendEmailWithEnterKey = (event) => {
    if (event.key === "Enter") {
      validateEmail();
    }
  };

  const handleSubmit = async () => {
    let response = await axios.post(
      " https://jupiter-test.com/api/invitation/subscribe-cryptodata",
      {
        email: emailValue,
      }
    );
    try {
      if (response.status === 200) {
        setSuccesufullySubscriber(true);
      }
    } catch (err) {}
  };

  return (
    <footer className="w-100">
      <div className="container-bg px-3 d-flex align-items-center flex-column justify-content-center w-100 position-relative">
        <img
          src={CryptoDataLogo}
          alt="cryptodata-logo"
          className="position-absolute cryptodata-logo"
        />
        <div className="z-index-1 text-center white-color font-gotham-medium font-size-32">
          Get the latest updates
        </div>
        <div className="z-index-1 text-center mb-5 white-color font-gotham-medium font-size-18">
          Only important updates. Never spam.
        </div>

        <div className="input-field d-flex align-items-center justify-content-center z-index-1">
          <div className="email-input-container">
            {!succesfullySubscriber ? (
              <InputGroup className="input-group mb-1">
                <Form.Control
                  className="big-input"
                  placeholder="Enter your email"
                  aria-label="Adresa de email"
                  aria-describedby="basic-addon2"
                  onChange={handleChange}
                  readOnly={emailSent}
                  onKeyDown={sendEmailWithEnterKey}
                />

                <Form.Control
                  className="small-input"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon2"
                  readOnly={emailSent}
                  onChange={handleChange}
                  onKeyDown={sendEmailWithEnterKey}
                />
                {/* <InputGroup.Append> */}
                <button
                  id="basic-addon-2"
                  className={`btn-primary noselect ${
                    emailSent && "email-sent"
                  }`}
                  onClick={(event) => {
                    !emailSent && validateEmail(event);
                  }}
                >
                  Subscribe
                </button>
                {/* </InputGroup.Append> */}
              </InputGroup>
            ) : (
              <h4 className="white-color font-gotham-bold">
                Succesfully Subscribed!
              </h4>
            )}

            <p className="ml-3 mt-0 pt-0 error-message font-gotham-book text-center">
              {emailErrorMessage}
            </p>
            <p className="ml-3 mt-0 pt-0 white-color text-center font-gotham-book text-center">
              {emailSentMessage}
            </p>
          </div>
        </div>
        <p className="z-index-1 text-center gray-color font-gotham-book paragtaph-container font-size-16">
          We’ll never share your details with third parties. View our{" "}
          <span
            className="privacy-link"
            onClick={() => window.open("https://cryptodata.com/privacy-policy")}
          >
            Privacy Policy
          </span>{" "}
          for more info.
        </p>
      </div>
      <div className="content-container-col">
        <div className="nav-container font-gotham-medium d-flex justify-content-start gray-color align-items-center mb-3">
          <img className="logo mr-2" src={logo} alt="" />
          <Chevron className="mx-2" /> Sici.AI
        </div>
        <Row xs={1} sm={1} md={1}>
          <Col md={4} className="d-flex flex-column footer-info-col">
            <p className="font-gotham-medium white-color mb-2 font-size-14">
              CryptoDATA
            </p>
            <p className="font-gotham-medium  gray-color font-size-14">
              Blvd Pipera, no 1/II <br />
              Voluntari, Ilfov, Romania
            </p>
            <div className="social-media-container d-flex align-items-center justify-content-start">
              <a
                href="https://www.youtube.com/c/CryptoDATAOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeLogo />
              </a>
              <a
                href="https://www.facebook.com/cryptodatamobileus"
                target="_blank"
                rel="noreferrer"
              >
                <FaceBookLogo />
              </a>
              <a
                href="https://t.me/joinchat/SbIUe1WNLavdynhT"
                target="_blank"
                rel="noreferrer"
              >
                <TelegramLogo />
              </a>
              {/* <a
                href="https://twitter.com/CryptoDATA_Tech"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramLogo />
              </a> */}
              <a
                href="https://twitter.com/CryptoDATA_Tech"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterLogo />
              </a>
            </div>
          </Col>

          {/* LINKS CONTAINER 1 START */}
          {/* For small screens */}
          <Col className="collapsable-footer-containers">
            <Accordion className="w-100">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="d-flex justify-content-between align-items-center">
                  <p className="font-gotham-medium white-color mb-2 font-size-14">
                    Products
                  </p>

                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/impulse"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    IMPulse
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/basm"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    BASM
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/hidr"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    HidR
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/minter"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    MinteR
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="d-flex justify-content-between align-items-center">
                  <p className="font-gotham-medium white-color mb-2 font-size-14">
                    Software
                  </p>

                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/wispr"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    WispR
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/cryptodata-os"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    CryptoData OS
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="d-flex justify-content-between align-items-center">
                  <p className="font-gotham-medium white-color mb-2 font-size-14">
                    Projects
                  </p>

                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    VOBP
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://bankofenergy.ro/"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    Bank of Energy
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    Momentum
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://tinker-edu.ro/"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Tinker
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="d-flex justify-content-between align-items-center">
                  <p className="font-gotham-medium white-color mb-2 font-size-14">
                    Resources
                  </p>

                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/contact"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    Contact us
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/shop"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    Shop online
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://news.cryptodata.com/"
                    className="font-gotham-medium gray-color mb-2 font-size-14 mb-3"
                  >
                    News
                  </a>
                  {/* <a href="#" className="font-gotham-medium gray-color mb-2 font-size-14 mb-3">
                      Order status
                    </a>
                    <a href="#" className="font-gotham-medium gray-color mb-2 font-size-14">
                      Shopping help
                    </a> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          {/* For larger screens */}
          <Col md={8} className="lg-footer-containers links-container-1">
            <Row xs={1} sm={1} md={2} lg={4}>
              <Col>
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="font-gotham-medium white-color mb-2 font-size-14 mb-3">
                    Products
                  </h2>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/impulse"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    IMPulse
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/basm"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    BASM
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/hidr"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    HidR
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/minter"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    MinteR
                  </a>
                </div>
              </Col>
              <Col>
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="font-gotham-medium white-color mb-2 font-size-14 mb-3">
                    Software
                  </h2>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/wispr"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    WispR
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/cryptodata-os"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    CryptoData OS
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://siciai.cryptodata.com/"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    SiciAI{" "}
                  </a>
                </div>
              </Col>
              <Col>
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="font-gotham-medium white-color mb-2 font-size-14 mb-3">
                    Projects
                  </h2>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    VOBP
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://bankofenergy.ro/"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Bank of Energy
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Momentum
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://tinker-edu.ro/"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Tinker
                  </a>
                </div>
              </Col>
              <Col>
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="font-gotham-medium white-color mb-2 font-size-14 mb-3">
                    Resources
                  </h2>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/contact"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Contact us
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/shop"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    Shop online
                  </a>
                  <a
                    // target="_blank"
                    rel="noreferrer"
                    href="https://news.cryptodata.com/"
                    className="font-gotham-medium gray-color mb-2 font-size-14"
                  >
                    News{" "}
                  </a>
                  {/* <a href="#" className="font-gotham-medium gray-color mb-2 font-size-14">
                    Order status
                  </a>
                  <a href="#" className="font-gotham-medium gray-color mb-2 font-size-14">
                    Shopping help
                  </a> */}
                </div>
              </Col>
            </Row>
            {/* <div className="w-100 d-flex flex-column align-items-center">
              <div className="d-flex flex-column utility-links-container">
                <p className="mb-3 text-left font-nunito-bold">Linkuri utile</p>
                <a
                  onClick={() => {
                    history.push("/");
                    scrollToTopPage();
                  }}
                >
                  Acasă
                </a>
                <a
                  href="https://tinker-edu.ro/despre-noi"
                  target="_blank"
                  rel="noreferrer"
                >
                  Despre noi
                </a>
                <a
                  href="https://tinker-edu.ro/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Platformă
                </a>
                <a
                  href="https://tinker-edu.ro/termeni-conditii"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termeni și condiții
                </a>
                <a
                  href="https://tinker-edu.ro/politica-confidentialitate"
                  target="_blank"
                  rel="noreferrer"
                >
                  Politica de confidențialitate
                </a>
              </div>
            </div> */}
          </Col>
          {/* LINKS CONTAINER 1 END */}

          {/* SUPPORT CONTAINER */}
          {/* For larger screens START */}
          {/* <Col md={4} className="lg-footer-containers ">
            <p className="mb-3 text-center font-nunito-bold">
              Ai nevoie de ajutor?
            </p>
            <div className="support-button-container">
              <Button
                id="footer-faq-btn"
                variant="primary"
                className="btn-primary"
                onClick={() => {
                  window.open("https://tinker-edu.ro/suport", "_blank");
                }}
              >
                Întrebări frecvente
              </Button>

              <p className="font-nunito-bold option">sau</p>

              <Button
                id="footer-contact-btn"
                variant="outline-primary"
                className="tn-outline-primary"
                onClick={() => {
                  window.open("https://tinker-edu.ro/contact", "_blank");
                }}
              >
                Contact
              </Button>
            </div>
          </Col> */}
          {/* For larger screens END */}

          {/* For smaller screens START */}
          {/* <Col className="collapsable-footer-containers">
            <Accordion className="w-100">
              <Card>
                <Accordion.Toggle
                  onClick={() => handleAccordion("openedSecond")}
                  as={Card.Header}
                  eventKey="0"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className="font-nunito-bold">Ai nevoie de ajutor?</p>
                  {columnCollapseState.openedSecond ? (
                    <AiOutlineClose className="icon" />
                  ) : (
                    <AiOutlinePlus className="icon" />
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div className="support-button-container">

                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col> */}
          {/* For smaller screens END */}
        </Row>
        <div className="bottom-footer mt-3 d-flex align-items-center justify-content-between flex-column-reverse flex-md-row">
          <div className="mt-3">
            <span className="font-gotham-medium  gray-color font-size-14 pt-3">
              Copyright © 2022 CryptoDATA
            </span>
          </div>

          {/* <div className="language-ico font-gotham-medium  gray-color font-size-14 pt-3 d-block d-md-none">
            <LanguageIco /> <span className="ml-1">English</span>
          </div> */}

          <div className="privacy-part font-gotham-medium gray-color font-size-14 d-flex align-items-center justify-content-center pt-3 text-center">
            <a
              // target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/privacy-policy"
              className="font-gotham-medium gray-color font-size-14 mb-0"
            >
              Privacy Policy
            </a>
            <span className="px-2">|</span>
            <a
              // target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/terms-and-conditions"
              className="font-gotham-medium gray-color font-size-14 mb-0"
            >
              Terms of Use
            </a>
            <span className="px-2">|</span>
            <a
              // target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/user-agreement"
              className="font-gotham-medium gray-color font-size-14 mb-0"
            >
              User Agreement
            </a>
          </div>

          <div className="language-ico font-gotham-medium  gray-color font-size-14 pt-3">
            <LanguageIco /> <span className="ml-1">English</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
