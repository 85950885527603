import React from "react";
import "./Hero.scss";

const Hero = () => {
  return (
    <section id="homepage-hero" className="d-flex w-100 h-100">
      <div className="h-auto w-100">
        <div className="article-container d-flex align-items-center justify-content-center flex-column">
          <h2 className="white-color font-size-56 font-gotham-bold title-container px-3 mb-4">
            SICI.AI
          </h2>
          <h4 className="text-center font-size-24 gray-color p-container px-3 mb-4">
            Sistem Inteligent Criptografic Integrat <strong>[SICI.AI]</strong>
            <br></br>
            <div className="mt-5 font-size-18">Cod SMIS proiect: 143454 </div>
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Hero;
