import React, { useEffect, useState } from "react";
import "./ChangeLog.scss";
import { ReactComponent as AiOutlinePlus } from "./Assets/plus.svg";
import { Accordion, Table, Card } from "react-bootstrap";
import SiciLogo from "../../../Components/Footer/Assets/black-images.png";
import MFE from "../../../Components/Footer/Assets/logo_MFE.png";

const ChangeLog = () => {
  return (
    <section id="changelog" className="pt-5">
      <div className="article-container">
        <img src={SiciLogo} alt=" " className="img-fluid p-5 mb-4" />
        <hr></hr>
        <Card.Text className="text-center  gray-color font-gotham-light mb-5">
          Programul Operaţional Competitivitate 2014–2020” – co-finanţat din
          Fondul European de Dezvoltare Regională <br></br> – Axa Prioritară 2 –
          „ Tehnologia informaţiei şi comunicaţiilor (TIC) pentru o economie
          digitală competitivă”
        </Card.Text>

        <div className="device-select-container d-flex align-items-end justify-content-between "></div>

        <Accordion className="w-100" defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start justify-content-start flex-column">
                <div className="d-flex"></div>

                <div className="my-2 font-gotham-medium font-size-24">
                  Prezentarea Proiectului{" "}
                </div>
                <div className="my-2 font-gotham-book font-size-20 text-uppercase"></div>
              </div>

              <AiOutlinePlus className="icon" />
            </Accordion.Header>
            <Accordion.Body className="d-flex flex-column">
              <div>
                <div className="font-size-24 gray-color">
                  Compania CryptoDATA Tech a identificat un domeniu de cercetare
                  si doreste implementarea rezultatului proiectului in domeniile
                  Big DATA, IoT (Internet of Things), CyberSecurity, Inteligenta
                  Artificiala si Digitalizare Industriala, sectoare foarte
                  importante nu numai in regiune, dar si pe plan international.
                  Prezentul proiect raspunde strategiei de dezvoltare a
                  companiei, permitand extinderea competentelor si a
                  portofoliului comercial in mod inovativ, intr-un domeniu nou,
                  cu relevanta si impact la nivel national si international, cu
                  aplicabilitate atat in sectorul public (prin utilizarea
                  produsului ca instrument de cercetare/dezvoltare in domeniul
                  administrației publice) cat si cel privat (la nivelul de
                  consumatori de produse noi si inovative). <br></br>
                  <br></br>
                  Implementarea Sistemului Inteligent Criptografic Integrat
                  “SICI.AI“ pe structura actuala a companiei CryptoDATA
                  presupune achizitia de echipamente hardware si programe
                  software, dar si dezvoltarea in departamente interne a
                  arhitecturii de functionare si a programelor software
                  dedicate. Acest sistem informatic este destinat cresterii
                  productivitatii companiei atat prin eficientizarea
                  procedurilor de lucru interne, cat si prin obtinerea de
                  venituri prin vanzarea directa a dispozitivelor si serviciilor
                  companiei. Sistemul informatic ofera un caracter operativ si
                  dinamic, atat companiei, cat si utilizatorilor.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start justify-content-start flex-column">
                <div className="d-flex"></div>

                <div className="my-2 font-gotham-medium font-size-24">
                  Obiectivele proiectului
                </div>
                <div className="my-2 font-gotham-book font-size-20 text-uppercase"></div>
              </div>

              <AiOutlinePlus className="icon" />
            </Accordion.Header>
            <Accordion.Body className="d-flex flex-column">
              <div>
                <div className="font-size-24 gray-color">
                  Obiectivul general al proiectului Sistemului Inteligent
                  Criptografic Integrat – SICI.AI de catre compania CryptoDATA
                  este cresterea si eficientizarea productivitatii companiei
                  prin dezvoltarea unei platforme cu elemente hardware si
                  software ce asigura comunicarea, transferul si integrarea
                  datelor intr-un mod securizat, dar care asigura si sortarea,
                  verificarea si validarea acestora cu ajutorul inteligentei
                  artificiale. Pe termen mediu si lung se urmareste pozitionarea
                  companiei in topul celor mai bune companii de tehnologie care
                  ofera solutii criptografice complexe, sisteme pentru
                  comunicare securizata, dar si module de inteligenta
                  artificiala special antrenate. <br></br>
                  <br></br>
                  Obiectivele specifice sunt urmatoarele:
                  <br></br>
                  <br></br>
                  Obiectiv specific #1: Cresterea capabilitatilor de lucru a
                  departamentului de cercetare si dezvoltare prin achizitia de
                  echipamente hardware (Sisteme multi-task pentru procesare
                  date, computere performante pentru compilare, servere storage,
                  imprimanta 3D, CNC si alte utilaje/echipamente necesare in
                  activitatile de cercetare-dezvoltare) si software (motor de
                  randare, motor de proiectare si testare).
                  <br></br>
                  <br></br>
                  Obiectiv specific #2: Realizarea platformei de operare a
                  sistemului inteligent criptografic integrat ce este special
                  destinata interoperabilitatii intre utilizatori. In cadrul
                  acestei platforme este necesara dezvoltarea unei
                  infrastructuri back-end ce include functii de sortare a
                  datelor, de generare de grafice pe baza datelor integrate in
                  sistem, de organizare a informatiilor pentru a verifica starea
                  in care se afla. Pentru a putea fi folosita de toti
                  utilizatorii, pentru functiile de back-end trebuie dezvoltata
                  o interfata de lucru ce este user-Friendly si care trebuie sa
                  fie usor de personalizat in functie de necesitati.
                  <br></br>
                  <br></br>
                  Obiectiv specific #3: Realizarea propotipului dispozitivelor
                  fizice de comunicare criptata cercetat si dezvoltate in cadrul
                  proiectului SICI.AI. Aceste dispozitive vor fi distribuite
                  membrilor companiei, colaboratorilor, furnizorilor, clientilor
                  dar care vor fi vandute si catre alti utilizatori ce doresc sa
                  isi imbunatateasca procedurile de lucru prin securizarea si
                  eficientizarea schimbului de informatii.
                  <br></br>
                  <br></br>
                  Obiectiv specific #4: Eficientizarea activitatii companiei
                  prin cercetarea si dezvoltarea unei suite de aplicatii
                  software inovative, securizate. Aceasta suita de aplicatii va
                  contribui la realizarea managementului activitatilor
                  celorlalti membri si pentru a analiza, verifica si imbunatati
                  modalitatile si procedurile de lucru. Prin implementarea
                  acestui sistem integrat si cu ajutorul functiilor aferente se
                  pot respecta si impune principiile undei dezvoltari durabile
                  si eficienta in desfasurarea activitatii companiei.
                  <br></br>
                  <br></br>
                  Obiectiv specific #5: Cresterea capacitatii de inteligenta
                  artificiala prin realizarea modulului de inteligenta
                  artificiala integrat in produsul inovativ SICI.AI Acest
                  obiectiv specific are o importanta deosebita, parte a
                  Infrastructurii sistemului SICI.AI, pentru ca acesta aduce un
                  plus de valoare adaugata si nivel ridicat de inovare unui
                  astfel de sistem informatic, facilitand derularea
                  activitatilor prin preluarea autonoma a functiilor cum ar fi:
                  culegere a datelor si antrenarea modelelor de invatare,
                  sortarea si analiza datelor transmise in sistem.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start justify-content-start flex-column">
                <div className="d-flex"></div>

                <div className="my-2 font-gotham-medium font-size-24">
                  Rezultate estimate
                </div>
                <div className="my-2 font-gotham-book font-size-20 text-uppercase"></div>
              </div>

              <AiOutlinePlus className="icon" />
            </Accordion.Header>
            <Accordion.Body className="d-flex flex-column">
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nr.</th>
                      <th>Detalii rezultat</th>
                      <th>Buget eligibil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        Rezultat #4: 1 Set activitati de informare (1 anunt
                        initial, 1 anunt final, 130 autocolante, 1 panou)
                      </td>
                      <td>11,495.40 (0.05%)</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Rezultat #9: 1 Raport audit tehnic</td>
                      <td>23,800.00 (0.10%)</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Rezultat #7: 1 cerere de brevet realizata</td>
                      <td>23,800.00 (0.10%)</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Rezultat #8: 1 Raport audit financiar</td>
                      <td>23,800.00 (0.10%)</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        Rezultat #5: 1 set (participarea la 2 conferinte
                        internationale, 2 articole stiintifice acceptate pentru
                        publicare){" "}
                      </td>
                      <td>59,500.00 (0.26%)</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        Rezultat #1: Cerere de finanţare, plan de afaceri,
                        documentatii suport
                      </td>
                      <td>154,700.00 (0.67%)</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>
                        Rezultat #2: Set documentatii de achizitii (12 contracte
                        cu terti)
                      </td>
                      <td>154,700.00 (0.67%)</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>
                        8. Rezultat #3: 1 set documentatii implementare proiect
                        (Echipa de proiect constituita; Plan de comunicare
                        stabilit la nivelul proiectului; Plan detaliat de
                        implementare al proiectului; Planul de management
                        actualizat ; Cereri de prefinantare, de rambursare si de
                        plata realizat
                      </td>
                      <td>154,700.00 (0.67%)</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>
                        {" "}
                        Rezultat #6: 1 set reclame si difuzari (1 reclama TV
                        realizata si 10 difuzari / 1 reclama radio realizata si
                        10 difuzar)
                      </td>
                      <td>357,000.00 (1.55%)</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>
                        Rezultat #11: 1 prototip produs inovativ SICI.AI
                        realizat
                      </td>
                      <td>1,976,063.40 (8.57%)</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>
                        Rezultat #10: 1 set active corporale si active
                        necorporale achizitionat; activitate de cercetare
                        realizata
                      </td>
                      <td>20,129,431.68 (87.26%)</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <strong>Buget Eligibil total:</strong>
                      </td>
                      <td>23,068,990.48 (100.00 %)</td>
                    </tr>
                  </tbody>
                </Table>{" "}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start justify-content-start flex-column">
                <div className="d-flex"></div>

                <div className="my-2 font-gotham-medium font-size-24">
                  Grup tinta
                </div>
                <div className="my-2 font-gotham-book font-size-20 text-uppercase"></div>
              </div>

              <AiOutlinePlus className="icon" />
            </Accordion.Header>
            <Accordion.Body className="d-flex flex-column">
              <div>
                <div className="font-size-24 gray-color">
                  Un studiu publicat in anul 2016 de compania americană Cisco
                  relevă faptul că strategiile eficiente pentru securitate
                  cibernetică au potenţialul de a genera afaceri de aproximativ
                  5.300 de miliarde de dolari în sectorul privat în următorii 10
                  ani. Din suma totală, o treime (aproximativ 1.700 miliarde
                  dolari) ar urma să fie cheltuită pentru protecţia drepturilor
                  de proprietate intelectuală şi pentru evitarea atacurilor
                  cibernetice asupra reţelelor companiilor private. <br></br>{" "}
                  <br></br>
                  Restul de 3.600 miliarde de dolari ar putea proveni din
                  cheltuieli făcute de organizaţiile dornice să îşi
                  îmbunătăţească practicile în materia securităţii cibernetice
                  cu scopul inovării şi dezvoltării. <br></br> <br></br>
                  Potrivit raportului “Where to Begin Your Journey to Digital
                  Value in the Private Sector”, şase industrii – producţie,
                  servicii financiare, comerţ, servicii, sănătate, petrol şi
                  gaze- vor contribui cu aproape trei sferturi din valoarea
                  globală a sectorului digital în următoarea decadă. <br></br>{" "}
                  <br></br>
                  Studiul menţionează că în timp ce tehnologiile pentru
                  segmentul consumer, precum echipamentele wearable (dispozitive
                  electronice care pot fi purtate ca accesorii vestimentare)
                  aduc o creştere semnificativă a valorii digitale în comparaţie
                  cu conexiunile business-to-business, soluţiile digitale axate
                  pe oameni, cum ar fi cele pentru colaborare video şi
                  mobilitate, ajută la creşterea capacităţii companiilor de a
                  inova mai repede şi mai eficient. <br></br> <br></br>
                  La nivel national, România se apropie de finalul ciclului de
                  creştere post criză, potrivit ultimelor date primite de la
                  Registrul Comerţului şi interpretate de analiştii KeysFin în
                  studiul “Trending Business”. Astfel, în 2019, România a ajuns
                  la performanţa de a avea cel mai mic număr de insolvenţe din
                  istorie (6500), respectiv cu 27% mai mic faţă de 2018 şi cu
                  67% faţă de 2009. De asemenea, o altă cifră record este şi cea
                  a entităţilor economice active (companii şi PFA-uri) care a
                  însumat 1,36 milioane de entităţi în România, în 2019. In
                  2019, în România au fost înfiinţate 134.200 de entităţi
                  economice, pe când numărul afacerilor care s-au închis
                  (dizolvări plus radieri) este de 135.700, sporul natural fiind
                  aşadar unul uşor negativ. <br></br> <br></br>
                  Ca dinamică a ultimilor 10 ani, numărul companiilor deschise a
                  crescut cu 16%, în timp ce numărul celor închise a crescut cu
                  117,5%. Decalajul dintre cei 2 indicatori poate fi explicat şi
                  prin faptul că numărul radierilor şi dizolvărilor este
                  puternic influenţat de măsurile administrative (spre exemplu
                  în 2010 au fost cuprinse aproximativ 127.294 radieri pentru
                  neexercitarea dreptului de opţiune în una din formele
                  prevăzute de OUG 44/2008 - sancţionată cu radierea din
                  Registrul Comerţului). Cu toate acestea, tendinţa de
                  consolidare, poate chiar de curăţare a economiei este evidentă
                  după avansul de peste 117,5% faţă de 2009, la 135.700 de
                  afaceri închise în 2019. Ca pondere din total entităţi active,
                  rata companiilor închise a fost de 9,9% şi este aproape egală
                  cu cea a celor nou înfiinţate în 2019 şi reprezintă un semnal
                  clar al apropierii de finalul de creştere post-criză. Pe
                  segmente, la sfârşitul lui 2019 existau 378.700 de PFA-uri
                  active (28% din total) versus 985 mii de companii (72% din
                  total). Ca dinamică în ultimii 5 ani se observă o preferinţă
                  în creştere pentru companii (avans de 27%) în detrimentul
                  PFA-urilor (scădere de 4%). <br></br> <br></br>
                  Pe segmente economice, în ultimii 5 ani sectoarele de
                  Construcţii, Transport & depozitare şi Industria prelucrătoare
                  au atras cel mai mult investitorii, fiind create cu 5400,
                  respectiv cu câte 3400 de entităţi economice fiecare mai multe
                  în 2019 decât în 2015, arată analiza KeysFin. <br></br>{" "}
                  <br></br>
                  Cea mai mare creştere procentuală în ultimii 5 ani, de peste
                  88% o înregistrează Sănătatea şi serviciile sociale. Dacă în
                  2015 existau aproximativ 1.800 afaceri nou înfiinţate în acest
                  sector, în 2019 au fost înfiinţate peste 3.300. Pe de altă
                  parte, raportat la numărul de înmatriculări de companii şi
                  PFA, cele mai populare domenii din România din 2019 sunt cele
                  aferente Comerţului – cu 29.300 de entităţi economice
                  înfiinţate în 2019, Construcţiilor – 14.800 şi Activităţilor
                  profesionale – cu 12.900 de entităţi economice. <br></br>{" "}
                  <br></br>
                  Geografia de business <br></br> <br></br>
                  În ultimii 10 ani, cel mai dinamic judeţ din punctul de vedere
                  al numărului de entităţi economice active a fost Ilfov, care a
                  înregistrat o creştere de 138%, astfel că în 2019 existau aici
                  aproximativ 50.000 de afaceri. În topul creşterilor
                  procentuale se regăsesc şi Dâmboviţa, care are cu 57% mai
                  multe entităţi active decât în 2009 şi Giurgiu cu 56%.{" "}
                  <br></br> <br></br>
                  15 La polul opus, cele mai mici creşteri în ceea ce priveşte
                  firmele înfiinţate s-au înregistrat în Harghita, cu o creştere
                  de doar 2,1% în ultimii 10 ani, precum şi în Botoşani – 4,8%
                  şi Bacău – 5%. <br></br> <br></br>
                  La nivel nominal însă, Bucureştiul rămâne lider cu peste
                  247.000 de entităţi (18% din total), urmat de Cluj şi Timiş,
                  cu 73.000 (5,3% din total), respectiv 58.500 (4,3% din total).
                  Podiumul este completat de Constanţa - 50.300 afaceri nou
                  înfiinţate şi Bihor -50.200. Din punct de vedere financiar al
                  potentialului pietei de IT, in Romania activează, în prezent,
                  peste 17.000 de firme în industria software, cu aproape 3.000
                  mai multe decât în anul de referinţă 2012. Afacerile acestora
                  au trecut, in anul 2017, de pragul de 4,9 miliarde de euro,
                  iar în 2018 s-a depăşit nivelul de 5,4 miliarde de euro.{" "}
                  <br></br> <br></br>
                  Raportat la 2007, afacerile din sectorul de software au
                  crescut cu 150%, iar faţă de 2016 cu 5,4%, semn că piaţa a
                  atins un nivel de maturizare, arată o analiză KeysFin.{" "}
                  <br></br> <br></br>
                  Cele mai mari afaceri le-au realizat, în 2017, firmele străine
                  prezente în România, în principal ORACLE ROMANIA SRL (221
                  milioane de euro/4,5% din total), IBM ROMANIA SRL (195
                  milioane de euro/3,9%) şi Ericsson România SRL (159 de
                  milioane de euro/ 3,2%). Topul cifrei de afaceri este
                  completat de Endava România (82,5 milioane de euro/1,7% din
                  total) şi Atos IT (73,3 milioane de euro/0,9% din total). La
                  data extragerii datelor financiare, în octombrie 2018, nu erau
                  informaţii oficiale disponibile despre Bitdefender SRL (locul
                  4 în 2016). În urma estimărilor KeysFin, jucătorul si-a
                  păstrat poziţia în 2017. Pe ansablu, potrivit analizei, cele
                  mai mari 10 companii generau 21% din totalul cifrei de afaceri
                  la nivel naţional. <br></br> <br></br>
                  La nivel geografic, cele mai mari afaceri au fost realizate de
                  firmele din Bucureşti-Ilfov (3 miliarde euro), Cluj (723
                  milioane euro), Iaşi (223 milioane de euro), Timiş (190 de
                  milioane de euro) şi Braşov (152 de milioane de euro).
                  Împreună, acestea au generat peste 86% din cifra de afaceri
                  totală în 2017. <br></br> <br></br>
                  In acest context, atat persoanele fizice cat si companiile
                  reprezinta piata potentiala pentru solutia inovativa SICI.AI.
                  In acest context, de zeci de milioane de posibili clienti
                  persoane fizice si sute de mii de companii doar in Romania,
                  compania isi propune sa obtina 300 de clienti pe an, numar mai
                  mult decat realist pentru contextul actual si viitor.
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="d-flex justify-content-center align-content-center flex-wrap">
          <Card className="mb-4 w-100" style={{ width: "18rem" }}>
            <Card.Img variant="top" className="p-5" src={MFE} />
            <Card.Body>
              {" "}
              <Card.Text className="text-center text-center gray-color  font-gotham-light">
                Pentru informaţii detaliate despre celelalte programe
                cofinanțate de Uniunea Europeana, vă invităm să vizitaţi
              </Card.Text>
              <Card.Title className="text-center">
                <a href="https://www.fonduri-ue.ro">www.fonduri-ue.ro</a>
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="mb-4 w-100" style={{ width: "18rem" }}>
            <h2 className="font-gotham-bold text-center mt-5">Contact</h2>
            {/* <Card.Img variant="top" className="p-5" src={MFE} /> */}
            <Card.Body>
              <Card.Title className="text-center font-gotham-book">
                Email
              </Card.Title>
              <Card.Text className="text-center gray-color  font-gotham-light">
                office@cryptodata.ro
              </Card.Text>

              <Card.Title className="text-center font-gotham-book">
                Telefon
              </Card.Title>
              <Card.Text className="text-center  gray-color font-gotham-light">
                +40 (0) 75 49 47 283
              </Card.Text>
              <Card.Title className="text-center font-gotham-book">
                Adresa
              </Card.Title>
              <Card.Text className="text-center  gray-color font-gotham-light">
                Blvd Pipera, no 1/II Voluntari, Ilfov, Romania
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <hr></hr>
        <Card.Text className="text-center  gray-color font-gotham-light">
          „Conținutul acestui material nu reprezintă în mod obligatoriu poziția
          oficială a Uniunii Europene sau a Guvernului României."
        </Card.Text>
      </div>
    </section>
  );
};

export default ChangeLog;
