import React from 'react'
import ChangeLog from './ChangeLog/ChangeLog'
import Hero from './Hero/Hero'

const Homepage = () => {
    return (

        <section id="homepage">
            <Hero />
            <ChangeLog />
        </section>
    )
}

export default Homepage